export default {
  methods: {
    getStorageKey () {
      return window['storage-' + this._uid]
    },
    setStorage (key, value) {
      localStorage.setItem(key, JSON.stringify(value))
    },
    getStorage (key) {
      return JSON.parse(localStorage.getItem(key))
    },
    initStorage (key) {
      if ([undefined, null].includes(key)) {
        console.error('Invalid storage key')
        return false
      }
      const baseStorage = this.storage
      const storageItem = this.getStorage(key)
      if (storageItem === null || storageItem.version < baseStorage.version) {
        this.setStorage(key, baseStorage)
      } else {
        this.storage = storageItem
      }
      window['storage-' + this._uid] = key
    }
  },
  watch: {
    storage: {
      handler: function (v) {
        const key = this.getStorageKey()
        if (key) this.setStorage(key, v)
      },
      deep: true
    }
  }
}