<template>
  <div class="copyright-list" v-if="authors && literature">
    <p><strong>{{ authors.subtitle }}</strong></p>
    <div class="links">
      <a v-for="file in authors.files" :key="file.id" :href="file.url" download>{{ file.title }}</a>
    </div>
    <details>
      <summary>{{ literature.subtitle }}</summary>
      <div v-html="literature.content"></div>
    </details>
  </div>
</template>

<script>
import axios from 'axios'

export default {
  name: 'CopyrightList',
  data () {
    return {
      authors: null,
      literature: null
    }
  },
  created () {
    this.getAuthors()
    this.getLiterature()
  },
  methods: {
    getAuthors () {
      axios.get('https://api2.kme.si/v2/articles/615692?with=files&resource_id=28').then(r => {
        if (r.status === 200) {
          this.authors = r.data.data
        }
      })
    },
    getLiterature () {
      axios.get('https://api2.kme.si/v2/articles/615679?resource_id=28').then(r => {
        if (r.status === 200) {
          this.literature = r.data.data
        }
      })
    }
  }
}
</script>

<style scoped>
.copyright-list {
  font-size: 0.7em;
}

p {
  text-align: center;
}

.links {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  width: 300px;
  max-width: 300px;
  margin: 0 auto;
}

.links a {
  opacity: 0.6;
  color: currentColor;
  text-decoration: none;
  padding: 3px;
  transition: opacity 200ms;
}

.links a:hover, details > summary:hover, details[open] > summary {
  opacity: 1;
}

details > summary {
  padding: 1em 0;
  text-align: center;
  cursor: pointer;
  opacity: 0.8;
  transition: opacity 200ms;
}

details > div {
  border: 1px solid currentColor;
  padding: 10px 5px;
  border-radius: 0.25rem;
}

details > div :deep(> ul) {
  margin: 0;
  padding: 0 16px;
  column-count: 3;
  column-width: 300px;
}

details > div :deep(> ul > li) {
  padding: 0 5px;
}
</style>