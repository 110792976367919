<template>
  <color-scheme-toggle v-model="storage.colorScheme"/>
  <a href="https://radioaktual.si/"><img src="./assets/logo.png" alt="Radio Aktual"></a>
  <div>
    <h1>{{ activeStation.label }}</h1>
    <audio-player v-model="storage" :src="activeStation.url"/>
  </div>
  <stations-list :stations="stations" :active="storage.station" @select="storage.station = $event"/>
  <copyright-list></copyright-list>
</template>

<script>
import storageHelper from '@/mixins/storageHelper'
import AudioPlayer from '@/components/AudioPlayer'
import StationsList from '@/components/StationsList'
import CopyrightList from '@/components/CopyrightList'
import ColorSchemeToggle from '@/components/ColorSchemeToggle'

export default {
  name: 'App',
  components: { ColorSchemeToggle, CopyrightList, StationsList, AudioPlayer },
  mixins: [storageHelper],
  data () {
    return {
      storage: {
        version: 2,
        station: 'radio.aktual',
        volume: 0.5,
        colorScheme: 'light'
      }
    }
  },
  created () {
    this.initStorage('radio-aktual')
    this.setColorScheme()
    this.setTitle()
  },
  methods: {
    setColorScheme () {
      document.documentElement.setAttribute('color-scheme', this.storage.colorScheme)
    },
    setTitle () {
      document.title = 'Radio Aktual | ' + this.activeStation.label
    }
  },
  watch: {
    'storage.colorScheme'() {
      this.setColorScheme()
    },
    'storage.station'() {
      this.setTitle()
    }
  },
  computed: {
    stations () {
      return {
        radio: {
          label: 'Radijske postaje',
          items: [
            { id: 'aktual', label: 'Osrednja Slovenija', url: 'https://live.radio.si/Aktual', history: 'https://api2.kme.si/v2/radio-live/aktual' },
            { id: 'aktual-o', label: 'Obala', url: 'https://live.radio.si/AktualO', history: 'https://api2.kme.si/v2/radio-live/aktual-osrednja' },
            { id: 'aktual-d', label: 'Studio D', url: 'https://live.radio.si/AktualD', history: 'https://api2.kme.si/v2/radio-live/aktual-osrednja' },
            { id: 'aktual-k', label: 'Kum', url: 'https://live.radio.si/AktualK', history: 'https://api2.kme.si/v2/radio-live/aktual' }
          ]
        },
        music: {
          label: 'Glasbene postaje',
          items: [
            { id: 'dalmacija', label: 'Dalmacija', url: 'https://live.radio.si/AktualDalmacija' },
            { id: 'galama', label: 'Galama - Fešta', url: 'https://live.radio.si/AktualGalama' },
            { id: 'nostalgija', label: 'Nostalgija', url: 'https://live.radio.si/AktualNostalgija' }
          ]
        }
      }
    },
    activeStation () {
      const path = this.storage.station.split('.')
      return this.stations[path[0]].items[this.stations[path[0]].items.findIndex(s => s.id === path[1])]
    }
  }
}
</script>

<style scoped>
a {
  display: block;
  width: 195px;
  max-width: 98%;
}

img {
  width: 100%;
}

h1 {
  font-size: 1.3em;
  text-align: center;
  margin-top: 0;
}
</style>
