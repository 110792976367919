<template>
  <fa-icon
    :icon="modelValue === 'light' ? 'fa-sun' : 'fa-moon'"
    fixed-width
    class="theme"
    :class="modelValue"
    @click="toggle"
  />
</template>

<script>
export default {
  name: 'ColorSchemeToggle',
  emits: ['update:modelValue'],
  props: {
    modelValue: String
  },
  methods: {
    toggle () {
      this.$emit('update:modelValue', this.modelValue === 'light' ? 'dark' : 'light')
    }
  }
}
</script>

<style scoped>
.theme {
  position: fixed;
  top: 10px;
  right: 10px;
  padding: 5px;
  font-size: 1.3em;
  opacity: 0.8;
  cursor: pointer;
  transition: opacity 200ms, transform 200ms;
}

.theme:hover {
  opacity: 1;
  transform: scale(1.1);
}

.theme.light {
  color: orange;
}

.theme.dark {
  color: #2b7bcf;
}
</style>