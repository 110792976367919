<template>
  <div v-if="src" class="audio-player">
    <audio :src="src" @play="playing = true" @pause="playing = false" autoplay ref="player"/>
    <div class="controls">
      <button
        @click="togglePlayback"
        :style="{ color: playing ? 'var(--success)' : 'var(--primary)' }"
      ><fa-icon :icon="playing ? 'fa-circle-pause' : 'fa-circle-play'" fixed-width/></button>
      <v-range
        v-model="storage.volume"
        :parse-method="function (v) { return v * 100 }"
        :emit-method="function (v) { return v / 100 }"
      />
    </div>
  </div>
</template>

<script>
import VRange from '@/components/VRange'
export default {
  name: 'AudioPlayer',
  components: { VRange },
  emits: ['update:modelValue'],
  props: {
    modelValue: Object,
    src: String
  },
  data () {
    return {
      storage: null,
      playing: false
    }
  },
  created () {
    this.parseModel()
    this.setVolume()
  },
  mounted () {
    this.setVolume()
  },
  methods: {
    togglePlayback() {
      const player = this.$refs.player
      if (player) this.playing ? player.pause() : player.load()
    },
    setVolume () {
      if (this.$refs.player) this.$refs.player.volume = this.storage.volume
    },
    parseModel () {
      if (!window.isEqual(this.storage, this.modelValue)) this.storage = window.cloneData(this.modelValue)
    },
    emitModel () {
      if (!window.isEqual(this.storage, this.modelValue)) this.$emit('update:modelValue', window.cloneData(this.storage))
    }
  },
  watch: {
    modelValue: {
      handler: function () {
        this.parseModel()
      },
      deep: true
    },
    storage: {
      handler: function () {
        this.emitModel()
      },
      deep: true
    },
    'storage.volume'() {
      this.setVolume()
    }
  }
}
</script>

<style scoped>
.audio-player {
  width: max-content;
  margin: 0 auto;
}

.controls {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  width: 200px;
}

.controls > button {
  border: 0;
  color: var(--primary);
  font-size: 4em;
  background: transparent;
  padding: 0;
  cursor: pointer;
  transition: transform 200ms, color 200ms;
}

.controls > button:hover {
  transform: scale(1.05);
}
</style>