<template>
  <div class="range" @wheel="handleWheel">
    <fa-icon :icon="value > 0 ? 'fa-volume-low' : 'fa-volume-xmark'" @click="value = min"/>
    <div
      class="slider"
      @mousedown="mouseDown = true"
      @touchstart="mouseDown = true"
      ref="slider"
    ><div class="value" :style="{ left: value + '%' }"></div></div>
    <fa-icon icon="fa-volume-high" @click="value = max"/>
  </div>
</template>

<script>
export default {
  name: 'VRange',
  emits: ['update:modelValue'],
  props: {
    modelValue: {
      type: Number,
      default: 0
    },
    parseMethod: {
      type: Function,
      default: function (v) {
        return v
      }
    },
    emitMethod: {
      type: Function,
      default: function (v) {
        return v
      }
    },
    min: {
      type: Number,
      default: 0
    },
    max: {
      type: Number,
      default: 100
    },
  },
  data () {
    return {
      value: 0,
      mouseDown: false
    }
  },
  created () {
    this.parseModel()
    document.documentElement.addEventListener('mouseup', this.handleMouseUp)
    document.documentElement.addEventListener('touchend', this.handleMouseUp)
    document.documentElement.addEventListener('mousemove', this.setValue)
    document.documentElement.addEventListener('touchmove', this.setValue)
  },
  methods: {
    handleMouseUp (ev) {
      this.setValue(ev)
      this.mouseDown = false
    },
    setValue (ev) {
      if (this.mouseDown) {
        const sliderBox = this.$refs.slider.getBoundingClientRect()
        const pos = ev.changedTouches ? ev.changedTouches[0].clientX : ev.x
        const width = sliderBox.width
        const minX = sliderBox.x
        const maxX = minX + width
        if (pos <= minX) {
          this.value = this.min
        } else if (pos >= maxX) {
          this.value = this.max
        } else {
          this.value = (pos - minX) / width * (this.max - this.min)
        }
      }
    },
    handleWheel (ev) {
      if (ev.deltaY < 0 || ev.deltaX > 0) {
        const newValue = Math.floor(this.value + 1)
        const maxValue = this.max
        this.value = (newValue < maxValue) ? newValue : maxValue
      }
      if (ev.deltaY > 0 || ev.deltaX < 0) {
        const newValue = Math.floor(this.value - 1)
        const minValue = this.min
        this.value = (newValue > minValue) ? newValue : minValue
      }
    },
    parseModel () {
      if (!window.isEqual(this.value, this.parseMethod(this.modelValue))) this.value = this.parseMethod(this.modelValue)
    },
    emitModel () {
      if (!window.isEqual(this.emitMethod(this.value), this.modelValue)) this.$emit('update:modelValue', this.emitMethod(this.value))
    },
  },
  watch: {
    modelValue () {
      this.parseModel()
    },
    value () {
      this.emitModel()
    }
  },
  beforeUnmount () {
    document.documentElement.removeEventListener('mouseup', this.handleMouseUp)
    document.documentElement.removeEventListener('touchend', this.handleMouseUp)
    document.documentElement.removeEventListener('mousemove', this.setValue)
    document.documentElement.removeEventListener('touchmove', this.setValue)
  }
}
</script>

<style scoped>
.range {
  display: flex;
  align-items: center;
  gap: 10px;
  width: 100%;
}

.range > .slider {
  flex: 1;
  background-color: currentColor;
  height: 4px;
  border-radius: 0.25rem;
  position: relative;
  cursor: pointer;
  user-select: none;
  opacity: 0.8;
  transition: opacity 200ms;
}

.range > .slider:hover {
  opacity: 1;
}

.range > .slider > .value {
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 1em;
  height: 1em;
  background-color: currentColor;
  border-radius: 100px;
  transition: transform 200ms;
}

.range > .slider:hover > .value {
  transform: translate(-50%, -50%) scale(1.1);
}

.range > :not(.slider) {
  cursor: pointer;
  transition: transform 200ms;
}

.range > :not(.slider):hover {
  transform: scale(1.1);
}
</style>