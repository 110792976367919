<template>
  <div class="stations">
    <div v-for="key in Object.keys(stations)" :key="key">
      <div class="label">{{ stations[key].label }}</div>
      <div>
        <button
          v-for="station in stations[key].items"
          :key="getStationPath(key, station)"
          :class="{ active: getStationPath(key, station) === active }"
          @click="$emit('select', getStationPath(key, station))"
        >{{ station.label }}</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'StationsList',
  props: {
    stations: Object,
    active: String
  },
  methods: {
    getStationPath (key, station) {
      return `${key}.${station.id}`
    }
  }
}
</script>

<style scoped>
.stations {
  display: grid;
  grid-template-columns: 1fr 1fr;
  width: 300px;
  max-width: 90%;
  gap: 5px;
}

.label {
  text-align: center;
  padding-bottom: 5px;
  margin-bottom: 5px;
  border-bottom: 1px solid currentColor;
}

.stations > div > div {
  display: flex;
  flex-direction: column;
  gap: 5px;
}

button {
  border: 0;
  padding: 5px;
  cursor: pointer;
  color: white;
  background-color: var(--primary);
  border-radius: .25rem;
  transition: background-color 200ms;
}

button:hover {
  background-color: var(--primary-success-middle);
}

.active {
  pointer-events: none;
  background-color: var(--success);
}
</style>