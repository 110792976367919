import { createApp } from 'vue'
import App from './App.vue'
import('./styles/app.css')
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import {
  faCirclePause, faCirclePlay,
  faMoon,
  faSun,
  faVolumeHigh,
  faVolumeLow,
  faVolumeXmark
} from '@fortawesome/free-solid-svg-icons'

library.add(faCirclePause, faCirclePlay, faVolumeXmark, faVolumeLow, faVolumeHigh, faSun, faMoon)

window.cloneData = data => JSON.parse(JSON.stringify(data))
window.arrayDiff = (arr1, arr2) => arr1.filter(v => !arr2.includes(v))

window.isArray = value => Array.isArray(value)
window.isObject = value => typeof value === 'object' && !window.isArray(value) && value !== null

window.isEqual = (val1, val2, options = {}) => {
  const opts = Object.assign({
    arrayCompare: false,
    ignoreKeys: []
  }, options)
  if (window.isArray(val1) && window.isArray(val2)) {
    if (val1.length !== val2.length) return false
    if (val1.length === 0) return true
    const ac = opts.arrayCompare
    if (ac && val1[0][ac] !== undefined) {
      return val1.every(value => {
        const index = val2.findIndex(v => v[ac] === value[ac])
        return index < 0 ? false : window.isEqual(value, val2[index])
      })
    } else {
      return val1.every((value, index) => window.isEqual(value, val2[index]))
    }
  }
  if (window.isObject(val1) && window.isObject(val2)) {
    let keys1 = []
    for (const key in val1) keys1.push(key)
    keys1 = window.arrayDiff(keys1, opts.ignoreKeys)
    keys1.sort()
    let keys2 = []
    for (const key in val2) keys2.push(key)
    keys2 = window.arrayDiff(keys2, opts.ignoreKeys)
    keys2.sort()
    if (keys1.length !== keys2.length || keys1.filter((v, i) => v !== keys2[i]).length > 0) return false
    for (const key of keys1) {
      if (!window.isEqual(val1[key], val2[key])) return false
    }
    return true
  }
  return val1 === val2
}

createApp(App)
  .component('fa-icon', FontAwesomeIcon)
  .mount('#app')
